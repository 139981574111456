import { Box, Button, IconButton, Typography, useTheme,Stack, Paper, Grid, Container,Card } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { mockDataNewsList } from "../../data/mockData";
import { LoadImageNewsSub, SelectNewsSub } from "../../context";
import { useState,useEffect } from "react";
import axios from "axios";

export default function NewsList(){
    const [newsList,setNewsList] = useState()
    useEffect(()=>{
      loadNewsList()
    }, [])
    const loadNewsList = () =>{
      
      axios.get(SelectNewsSub)
      .then(res=>{
        
        setNewsList(res.data);
    
      }).catch(error=>{
        console.log(error);
      })
      }
      const handleNewsDetail =(e)=>{
        localStorage.removeItem('newsSubId')
        localStorage.newsSubId=e;
        window.open('/newsListDetail', '_blank', 'noreferrer')
      }
    return(
        <Box style={{maxHeight: 415, overflow: 'auto'}}>
            <Stack variant={'row'} >
                    {newsList ? newsList.map((item,index)=>(
                
                        <Card sx={{ display: 'flex',cursor:'pointer',borderBottom:'solid 1px #b5b5b57a'}} onClick={()=>handleNewsDetail(item.id)}>
                            <CardMedia
                                sx={{cursor:'pointer',width:160,height:110, margin:1,borderRadius:'30px',objectFit:'cover',objectPosition:'center'}}
                                image={LoadImageNewsSub + item.newssub_image}
                            />
                            <CardContent style={{paddingBottom:0}} >
                            <Typography variant="h5" component="div" sx={{width: 160,whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.newssub_topic}</Typography>
                            <Typography variant="h6" component="div" sx={{width: 160,height: 75,marginTop:-1,overflow:'hidden',textOverflow:'ellipsis',fontSize:13}}><div dangerouslySetInnerHTML={{ __html: item.newssub_detail }} /></Typography>
                            <Box textAlign='end' paddingTop={0.5} paddingBottom={0.5}>  <CalendarMonthIcon sx={{verticalAlign:'sub',fontSize:'small'}} /><Typography variant="h6" display='contents' sx={{fontSize: 12}}> วันที่ {item.newssub_create}</Typography>
                            </Box>
                            </CardContent>         
                        </Card>

                    )):''}
                    
                    
            </Stack>
        </Box>)
    }
