import { Box, IconButton, Menu, MenuItem, makeStyles, useMediaQuery, useTheme } from "@mui/material";
import { useContext,useRef } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { AppContext } from "../../AppContext";
import {useNavigate,useLocation} from 'react-router-dom';
function Topbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const location = useLocation()
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const {
    newArrivalRef,
    homeRef,
    socialContact,
    news
  } = useContext(AppContext);
  const handleClick = (ref) => {
    setAnchorElNav(null);
    {location.pathname != '/' && navigate('/')} 
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      
    </Box>
  );

 // const container = window !== undefined ? () => window().document.body : undefined;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  return (
    <Box sx={{ display: 'flex'}}>
      <AppBar component="nav" position="static">
        <Toolbar disableGutters>
            <img
                  alt="profile-user"
                  width="50px"
                  src={`../../assets/bigapple.jpg`}
                  style={{ cursor: "pointer", borderRadius: "50%", marginRight:'10px',marginLeft:'10px'}}
                  onClick={()=>{navigate('/')}}
            />
          <Typography
            variant="logo"
            component="div"
            
            onClick={()=>{navigate('/')}}
          >
           BIGAPPLE CAR
          </Typography>
          <Box width={'65%'} textAlign={'center'} paddingRight={20} sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} >
           <Button sx={{
              color: "#FFF",
              marginRight:'20px'  
            }}
            onClick={()=> handleClick(homeRef)}
            >
          <Typography
            variant="h4"                    
          >
           HOME
          </Typography>
          </Button>
          {location.pathname == '/' ?
        <>
        <Button sx={{
               color: "#FFF",
               marginRight:'20px'  
            }}
            onClick={()=>handleClick(newArrivalRef)}
            >
          <Typography
            variant="h4"
          
          >
            NEW ARRIVAL
          </Typography>
          </Button>

          <Button sx={{
               color: "#FFF",
               marginRight:'20px'  
            }}
            onClick={()=>handleClick(news)}
            >
          <Typography
            variant="h4"
          
          >
          BIGAPPLE NEWS
          </Typography>
          </Button>
          <Button sx={{
               color: "#FFF",
               marginRight:'20px'  
            }}
            onClick={()=>handleClick(socialContact)}
            >
          <Typography
            variant="h4"
          
          >
            SOCIAL CONTENT
          </Typography>
          </Button>

          </>  :'' }
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:'flex-end' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
           
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}             
              sx={{"& .MuiMenu-paper": 
              {position:'static' },
                display: { xs: 'block', md: 'none' },
              }}
            >
              
                <MenuItem sx={{justifyContent:'center'}} onClick={()=> handleClick(homeRef)}>
                  <Typography >HOME</Typography>
                </MenuItem>
                <MenuItem sx={{justifyContent:'center'}} onClick={()=> handleClick(newArrivalRef)}>
                  <Typography>NEW ARRIVAL</Typography>
                </MenuItem>
                <MenuItem  sx={{justifyContent:'center'}}onClick={()=> handleClick(news)}>
                  <Typography >BIGAPPLE NEWS</Typography>
                </MenuItem>
                <MenuItem sx={{justifyContent:'center'}} onClick={()=> handleClick(socialContact)}>
                  <Typography>SOCIAL CONTENT</Typography>
                </MenuItem>
                <MenuItem sx={{justifyContent:'center'}}>
                    <IconButton onClick={colorMode.toggleColorMode}>
                      {theme.palette.mode === "dark" ? (<>
                        <DarkModeOutlinedIcon sx={{color:'white'}}/>
                        <Typography ml={2}> DARK</Typography>
                      </>
                       
                      ) : (<>
                       <LightModeOutlinedIcon sx={{color:'black'}}/>
                       <Typography ml={2}> LIGHT</Typography>
                       
                       </>
                       
                      )}
                    </IconButton>
                </MenuItem>
               
            </Menu>
          </Box>
       
          <Stack textAlign={'center'}spacing={0}   sx={{ flexGrow: 1,  display: { xs: 'none', sm: 'block' } }}>
          <Typography variant="h5">
            Call: (66+)88 820 4444
          </Typography>
          <Typography variant="h6">
              Open every day
          </Typography>
          </Stack>

          <Box sx={{marginLeft:'10px', display: { xs: 'none', sm: 'block' } }}>
            <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon sx={{color:'white'}}/>
            ) : (
              <LightModeOutlinedIcon sx={{color:'white'}}/>
            )}
          </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

     
    </Box>
  );
}

Topbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Topbar;