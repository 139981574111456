import { Box, Button, IconButton, Typography, useTheme,Stack, Paper, Grid, Container,Card } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { SelectCarAll,SelectCarByBrand, SelectCarSearch } from "../../context";
import { tokens } from "../../theme";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useContext, useEffect, useState } from "react";
import { mockDataCarShow,mockDataNewCar,mockDataBrand} from "../../data/mockData";
import SelectBrand from "./menubar";
import CardCardSell from "./_carCardList";
const Carlist = ({type}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [car,setCar] = useState(null)
  const [carForPage,setCarForPage] = useState(null)
  const [page,setPage] = useState(1)
  const [pageCount,setPageCount] = useState(0)
  let itemPerPage = 12
  const {
    cookies,
    setCookie,
    removeCookie
  } = useContext(AppContext);
  const loadCarAll = () =>{
    
    axios.get(SelectCarAll)
    .then(res=>{
      
      setCar(res.data);
      setPageCount(Math.ceil(res.data.length / itemPerPage))
      console.log(Math.ceil(res.data.length / itemPerPage))
      var sliced = res.data.slice(0, itemPerPage); 
      setCarForPage(sliced)

    }).catch(error=>{
      console.log(error);
    })
    }
    const loadCarBrand = (brand_id) =>{
     
      var formData = new FormData();       
      formData.append("id", brand_id);      
      axios.post(SelectCarByBrand,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{     
        setCar(res.data);
        setPageCount(Math.ceil(res.data.length / itemPerPage))
     
        var sliced = res.data.slice(0, itemPerPage); 
        setCarForPage(sliced)
      }).catch(error=>{
     
      })
      }
    const loadCarByModel = (brand_id,model) =>{
    
      var formData = new FormData();       
      formData.append("id", brand_id);      
      formData.append("model",model)
      axios.post(SelectCarSearch,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{     
        setCar(res.data);
        console.log(res.data)
        setPageCount(Math.ceil(res.data.length / itemPerPage))

        var sliced = res.data.slice(0, itemPerPage); 
        setCarForPage(sliced)
      }).catch(error=>{
      
      })
      }

useEffect(()=>{

  if(JSON.stringify(cookies.brand) == undefined){
    loadCarAll()
     //window.location.replace("/");
   }

  else {

    loadCarBrand( JSON.stringify(cookies.brand) )
    removeCookie("brand",true)
 
 }
},[])
   
   const fetchData = (event, value)  =>{
      setPage(value)
      var sliced = car.slice((itemPerPage*value) -itemPerPage, itemPerPage*value); 
      setCarForPage(sliced)
      window.scrollTo({ top: 0, behavior: 'smooth' });
   }
   const handleCardetail = (e)=>{
    setCookie('carDetail',e)     
    const encode = Math.floor(Date.now() / 1000).toString() + '$' + e.toString()
   
    window.open('/carDetail/'+btoa(encode), '_blank', 'noreferrer')
 }
 const handleSearch = ({brandSelect,model})=>{    
    if(model == 0 )loadCarBrand(brandSelect)
    else loadCarByModel(brandSelect,model)
}

    return (
      <>
    <Box paddingTop={3} >
    <Container maxWidth='lg' >
      
      
      <Paper  style={{backgroundColor:colors.primary[400],padding:20}} >
          {/* <Typography variant="h2">{sessionStorage.getItem("brand")}</Typography> */}
          <SelectBrand brand = { JSON.stringify(cookies.brand)} search={(e)=>handleSearch(e)}></SelectBrand>
      </Paper>
      <br/>
      <Paper  style={{backgroundColor:colors.primary[400],padding:20}} >
          {/* <Typography variant="h2">{sessionStorage.getItem("brand")}</Typography> */}
          <CardCardSell mockData={carForPage ?? []} mockPomo={mockDataNewCar} handleCardetail={(e)=>handleCardetail(e)} ></CardCardSell>
          <br/>
          <Pagination onChange={fetchData} page={page} style={{display:'flex',placeContent:'center'}} count={pageCount} />
      </Paper>
  
      <br/>







    </Container>
        
    </Box>
  
    </>
  );
};

export default Carlist;
