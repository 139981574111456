import { Box, Button, IconButton, Typography, useTheme,Stack , Grid } from "@mui/material";
import { tokens } from "../../theme";
import axios from 'axios';
import { SelectBrandName,LoadLogo } from "../../context";
import { useEffect } from "react";
import { useState } from "react";
const BrandList = ({handleBrand}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data,setData] = useState(null)
  const loadItem = ()=>{
    axios.get(SelectBrandName)
    .then(res=>{
     
       setData(res.data);

    }).catch(error=>{
    
    })

    }
    useEffect(()=>{loadItem()},[])
    return (
      <>
    <Box paddingTop={5} display='grid' sx={{ display: { xs: 'none', sm: 'grid' }}} >
        <Grid container spacing={{ xs:1 , md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} >
            
            {data ? data.map((value,index) => (
                <Grid item  key={index}>
                   <Button  onClick={()=>handleBrand(value.id)} sx={{ borderRadius:'20px',animation:'2s anim-brandButton',animationDelay:index*50+'ms', padding:'10px',display:'flex',flexDirection:'column',width:'140px',
                   height:'100px',background:colors.grey[900],border: 1,borderColor:colors.grey[800] }}>
                    <img 
                    width="60px"
                    height="30px"
                    object                   
                    src={LoadLogo+value.brand_logo}
                    style={{objectFit:'contain'}}/>
                    <Typography variant="bigappleFont"   color={colors.primary[100]} fontSize={15} >{value.brand_name}</Typography>
                    <Typography variant="bigappleFont" color={colors.primary[100]} fontSize={10} >instock ({value.brandSum})</Typography>
                    </Button>
                </Grid>
            )):''}
            </Grid>
        
            
    </Box>
    <Box paddingTop={5} display='grid' sx={{ display: { xs: 'grid', sm: 'none' }}} >
    <Grid container spacing={{ xs:1 , md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{placeContent:'center'}} >
        
        {data ? data.map((value,index) => (
            <Grid item  key={index}>
               <Button  onClick={()=>handleBrand(value.id)} sx={{ borderRadius:'20px',animation:'2s anim-brandButton',animationDelay:index*50+'ms', padding:'10px',display:'flex',flexDirection:'column',minWidth:'110px',
               height:'100px',background:colors.grey[900],border: 1,borderColor:colors.grey[800] }}>
                <img 
                width="60px"
                height="30px"
                object                   
                src={LoadLogo+value.brand_logo}
                style={{objectFit:'contain'}}/>
                <Typography variant="bigappleFont"   color={colors.primary[100]} fontSize={13} >{value.brand_name}</Typography>
                <Typography variant="bigappleFont" color={colors.primary[100]} fontSize={8} >instock ({value.brandSum})</Typography>
                </Button>
            </Grid>
        )):''}
        </Grid>
    
        
    </Box>
</>
  );
};

export default BrandList;
