

import React,{useState,useEffect,useContext} from 'react';
import Card from '@mui/material/Card';
import { LoadImageCar } from "../../context";
import {  Box, Stack, useTheme } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SpeedIcon from '@mui/icons-material/Speed';
import { colors } from '@mui/material';
import { tokens } from "../../theme";
import Tooltip from '@mui/material/Tooltip';
import { AppContext } from "../../AppContext";
import { NumericFormat } from 'react-number-format';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
export default  function CardCardPromo({mockData,mockPomo}) {
    const theme = useTheme();
    const [index,setIndex] = useState(0)
    const colors = tokens(theme.palette.mode);
    let widthInit = 100
    let i =0
    const {
      setCookie
    } = useContext(AppContext);
    function currencyFormat(num) {
      return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
   }
   const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  
  
    useEffect(() => {
      
      const interval = setInterval(async () => {
      // const randIndex = Math.floor(Math.random() * ((mockData.length-1) - 0 + 1) + 0) ;
        
        i = i+1
        
        if(i == mockPomo.length)  i = 0
        // console.log(i)
        setIndex(() => 
               i
        );
        // await delay(2000)
        // setChenge(true)
        // index+1==mockData.length ?? (setIndex(0))
      }, 4000);
      return () => {clearInterval(interval)  };
    }, [mockPomo]);
  
    const handleCardetail = (e)=>{
      setCookie('carDetail',e)     
      const encode = Math.floor(Date.now() / 1000).toString() + '$' + e.toString()
   
      window.open('/carDetail/'+btoa(encode), '_blank', 'noreferrer')
   }
  
    const cardPromo = (value) =>{
      
      return( 
      <Grid item   key={0}>
      
        <Card  sx={{ maxWidth: 275-widthInit ,minWidth: 275-widthInit,borderRadius:'30px', animation:'4000ms anim-brandButton infinite'}}>
        <Typography className='ribbon-2' variant='logo' fontSize={10}>HOT SALE</Typography>
         <CardMedia
             sx={{ height: 200-widthInit,margin:1,borderRadius:'15px' }}
             image={LoadImageCar+'Thumbnails_'+value.image_pathEx }
            
           />
           <CardContent sx={{p:1}}>
           <Tooltip followCursor title= { <Typography gutterBottom variant="logo" fontSize={13} component="div">
                           {value.brand_name + ' '+  value.car_model} 
                          </Typography>} >

                          <Typography gutterBottom variant="logo" fontSize={13} component="div" sx={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                           {value.brand_name + ' '+  value.car_model} 
                          </Typography>
                          </Tooltip>
             <Grid container xs={12}>
               <Grid item marginBottom={1} xs={6}><SpeedIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{currencyFormat(parseInt(value.car_mile ))}</Typography></Grid>
               <Grid item marginBottom={1} xs={6}><ColorLensIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{value.color_name}</Typography></Grid>
               <Grid item marginBottom={1} xs={6}><ScheduleSendIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{value.car_year}</Typography></Grid>
               <Grid item marginBottom={1} xs={6}><GasMeterIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{value.car_fuel}</Typography></Grid>
             </Grid>
           </CardContent>
          
           <Divider sx={{width:'80%',marginLeft:3,marginRight:3 }}></Divider>
           <CardActions>
             <Grid container>
               <Grid item xs={8}>
             <Typography paddingLeft={1} fontSize={15} variant="logo" color={colors.greenAccent[1000]}>
               {'฿ ' +currencyFormat(parseInt(value.car_price))}
             </Typography>
             </Grid>
             <Grid item xs={4}>
             <Button size="small" onClick={()=> handleCardetail(value.id)} sx={{background:colors.blueAccent[700],color:'#FFF',minWidth:0}}> <Typography  fontSize={10} >More</Typography></Button>
             </Grid>
             </Grid>
           </CardActions>
           
         </Card>
      
     </Grid>)
    }
  
    return (
      <Grid container spacing={{ xs:1 , md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
      alignItems="center"
      >   
             {cardPromo(mockPomo[index])}
              {mockData.map((value,index) => (
                
                  <Grid item  key={index+1}>
                      <Card  sx={{ maxWidth: 275-widthInit ,minWidth: 275-widthInit,borderRadius:'30px'}}>
        
         <CardMedia
             sx={{ height: 200-widthInit,margin:1,borderRadius:'15px' }}
             image={LoadImageCar+'Thumbnails_'+value.image_pathEx }
            
           />
                        <CardContent sx={{p:1}}>
                        <Tooltip followCursor title= { <Typography gutterBottom variant="logo" fontSize={13} component="div">
                           {value.brand_name + ' '+  value.car_model} 
                          </Typography>} >

                          <Typography gutterBottom variant="logo" fontSize={13} component="div" sx={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                           {value.brand_name + ' '+  value.car_model} 
                          </Typography>
                          </Tooltip>
                          <Grid container xs={12}>
                            <Grid item marginBottom={1} xs={6}><SpeedIcon  sx={{fontSize:15,marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{currencyFormat(parseInt(value.car_mile))}</Typography></Grid>
                            <Grid item marginBottom={1} xs={6}><ColorLensIcon  sx={{fontSize:15,marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{value.color_name}</Typography></Grid>
                            <Grid item marginBottom={1} xs={6}><ScheduleSendIcon  sx={{fontSize:15,marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{value.car_year}</Typography></Grid>
                            <Grid item marginBottom={1} xs={6}><GasMeterIcon  sx={{fontSize:15,marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={12}>{value.car_fuel}</Typography></Grid>
                          </Grid>
                  </CardContent>
                        <Divider sx={{width:'80%',marginLeft:3,marginRight:3 }}></Divider>
                        <CardActions>
                          <Grid container>
                            <Grid item xs={8}>
                            <NumericFormat
                                  value={parseInt ( value.car_price)}
                                  thousandsGroupStyle="none"
                                  thousandSeparator=","
                                  displayType="text"
                                  renderText={(value) =><Typography paddingLeft={1} fontSize={15} variant="logo" color={colors.greenAccent[1000]}>
                                  {'฿ ' +value}
                                </Typography>}
                                />
                          
                          </Grid>
                          <Grid item xs={4}>
                          <Button size="small" onClick={()=> handleCardetail(value.id)} sx={{background:colors.blueAccent[700],color:'#FFF',minWidth:0}}> <Typography  fontSize={10} >More</Typography></Button>
                          </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                  </Grid>
              ))}
         </Grid>
  
     
    );
  }