import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { mockDataBrand,mockDataCar } from '../../data/mockData';
import { Box } from '@mui/material';
import { Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { SelectBrandName, SelectModelAll, SelectModelByBrand } from '../../context';
import { useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
export default function SelectBrand({brand,search}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    cookies,
    setCookie,
    removeCookie
  } = useContext(AppContext);
  const [brandSelect, setBrand] = React.useState(0);
  const [brandList, setBrandList] = React.useState(null);
  const [model, setModel] = React.useState(0);
  const [modelName, setModelName] = React.useState('');
  const [modelList, setModelList] = React.useState(null);
  const [year, setYear] = React.useState('');
  const [color, setColor] = React.useState('');

  const handleBrandChange = (event) => {
    loadCarModellistByBrand(event.target.value)
    setModel(0)
    setBrand(event.target.value);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleSearch = () => {
  
     search({brandSelect,model})
  };
  useEffect(()=>{
    loadCarAll()

  },[])
  const loadCarAll = () =>{
    
  
    axios.get(SelectBrandName)
    .then(res=>{
    
      setBrandList(res.data)
      setBrand(brand ?? 0);
      //if(!brand) loadCarModellistAll()
      if(brand) loadCarModellistByBrand(brand)

    }).catch(error=>{
      console.log(error);
    })
    }
  const loadCarModellistAll = () =>{
  
    axios.get(SelectModelAll)
    .then(res=>{
       setModelList(res.data)
    }).catch(error=>{
      console.log(error);
    })
    }
  const loadCarModellistByBrand = (e) =>{

    var formData = new FormData();       
    formData.append("id", e);      
    axios.post(SelectModelByBrand,
    formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res=>{
        setModelList(res.data)
    }).catch(error=>{
      console.log(error);
    })
    }
  
  
  return (<>
    <Box sx={{display: { xs: 'none', sm: 'flex' },justifyContent:'center'}}>
    <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
      <InputLabel id="demo-select-small-label">BRAND</InputLabel>
    
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={brandSelect}
        label="BRAND"
        onChange={handleBrandChange}
      >
        <MenuItem value={0}>
          <em>None</em>
        </MenuItem>
       {brandList ? brandList.map((item,index)=>(             
            <MenuItem style={{textTransform:'uppercase'}} value={item.id}>{item.brand_name}</MenuItem>
        )):''}
       
        
      </Select>

    </FormControl>
        <FormControl sx={{ m: 1, minWidth: 200}} size="small">
        <InputLabel id="demo-select-small-label">MODEL</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={model}
          label="MODEL"
          onChange={handleModelChange}
        >
          <MenuItem value={0} key={'None'}>
            <em>None</em>
          </MenuItem>
         {modelList? modelList.map((item)=>(
          <MenuItem value={item.id} >{item.car_model}</MenuItem> 
            
          )):''}
         
          
        </Select>
      </FormControl>

      <Button size="small" onClick = {()=> handleSearch()} sx={{background:colors.blueAccent[600],color:'#FFF',width:'150px',height:'35px',marginLeft:'20px',marginTop:'8px'}}>SEARCH CAR</Button>
     
      </Box>


    <Box sx={{ display: { xs: 'flex', sm: 'none' },alignItems:'center'}}>


    <FormControl sx={{ m: 1, width:140 }} size="small">
      <InputLabel id="demo-select-small-label">BRAND</InputLabel>
    
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={brandSelect}
        label="BRAND"
        onChange={handleBrandChange}
      >
        <MenuItem value={0}>
          <em>None</em>
        </MenuItem>
       {brandList ? brandList.map((item,index)=>(             
            <MenuItem style={{textTransform:'uppercase'}} value={item.id}>{item.brand_name}</MenuItem>
        )):''}
       
        
      </Select>

    </FormControl>
        <FormControl sx={{ m: 1, width: 180}} size="small">
        <InputLabel id="demo-select-small-label">MODEL</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={model}
          label="MODEL"
          onChange={handleModelChange}
        >
          <MenuItem value={0} key={'None'}>
            <em>None</em>
          </MenuItem>
         {modelList? modelList.map((item)=>(
          <MenuItem value={item.id} >{item.car_model}</MenuItem> 
            
          )):''}
         
          
        </Select>
      </FormControl>
      

      <Button size="small" onClick = {()=> handleSearch()} sx={{background:colors.blueAccent[600],color:'#FFF',width:'100px',height:'35px'}}>SEARCH CAR</Button>
     
    </Box>
    </>
  );
}