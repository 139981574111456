//export const baseUrl =  'http://localhost:8000'
export const baseUrl =  'https://server.bigapplecars.com'

export const url = baseUrl+'/server.php'
export const SaveLogo = baseUrl+'/file/saveLogo.php'
export const SaveBrandName = baseUrl+'/brand/insert.php'
export const DeleteBrandName = baseUrl+'/brand/delete.php'
export const UpdateBrandName = baseUrl+'/brand/update.php'
export const SelectBrandName = baseUrl+'/brand/select.php'
export const SelectColor = baseUrl+'/color/select.php'
export const DeleteColor = baseUrl+'/color/delete.php'
export const UpdateColor = baseUrl+'/color/update.php'
export const SaveColor = baseUrl+'/color/insert.php'
export const SelectCar = baseUrl+'/car/select.php'
export const DeleteCar = baseUrl+'/car/delete.php'
export const UpdateCar = baseUrl+'/car/update.php'
export const SaveImagePath = baseUrl+'/car/insertImage.php'
export const SaveVideoPath = baseUrl+'/car/insertVideo.php'
export const SelectImageCar = baseUrl+'/car/selectImage.php'
export const DeleteImageCar = baseUrl+'/car/deleteImage.php'
export const DeleteVideoCar = baseUrl+'/car/deleteVideo.php'
export const SaveCarImg = baseUrl+'/file/saveCarImg.php'
export const SaveCarVideo = baseUrl+'/file/saveCarVideo.php'
export const DeleteCarImg = baseUrl+'/file/deleteCarImg.php'
export const DeleteCarVideo = baseUrl+'/file/deleteCarVideo.php'
export const SaveCar = baseUrl+'/car/insert.php'
export const DeleteLogo = baseUrl+'/file/deleteLogo.php'
export const LoadLogo = baseUrl+'/Upload/brandLogo/'
export const LoadImageCar = baseUrl+'/Upload/carImage/'
export const LoadVideoCar = baseUrl+'/Upload/carVideo/'
export const LoadImageNewsTopic = baseUrl+'/Upload/newsTopic/'
export const LoadImageNewsSub = baseUrl+'/Upload/newsSub/'
export const SelectNewArrival = baseUrl+'/newArrival/select.php'
export const SelectPromotion = baseUrl+'/newArrival/selectPromotion.php'
export const SelectCarAll = baseUrl+'/carall/select.php'
export const SelectCarByBrand = baseUrl+'/carall/selectByBrand.php'
export const SelectCarById = baseUrl+'/carall/selectById.php'
export const SaveNewsTopic = baseUrl+'/newsTopic/insert.php'
export const SaveImageTopic = baseUrl+'/file/saveNewsTopic.php'
export const UpdateNewsTopic = baseUrl+'/newsTopic/update.php'
export const SelectNewsTopicById = baseUrl+'/newsTopic/selectById.php'
export const SelectNewsTopic = baseUrl+'/newsTopic/select.php'
export const SelectNewsSubById = baseUrl+'/newsSub/selectById.php'
export const SelectNewsSub = baseUrl+'/newsSub/select.php'
export const SelectModelAll = baseUrl+'/carall/selectModel.php'
export const SelectModelByBrand = baseUrl+'/carall/selectModelByBrand.php'
export const SelectCarSearch = baseUrl+'/search/searchBrand.php'
export const SaveMailBox = baseUrl+'/mailBox/insert.php'