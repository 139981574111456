import { Box, Paper, Container, Typography, useTheme,Stack } from "@mui/material";
import { tokens } from "../../theme";
import { SelectNewsTopicById,LoadImageNewsTopic } from "../../context";
import axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";
import { mockDataBrand } from "../../data/mockData";


const NewsDetail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newsDetail,setNewsDetail] = useState()
    useEffect(()=>{
                
            var formData = new FormData();       
            formData.append("id",   localStorage.newsTopicId);      
            axios.post(SelectNewsTopicById,
            formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(res=>{     
                setNewsDetail(res.data[0])
               
        
            }).catch(error=>{
              console.log(error)
            })
           
    },[])

    const divStyle = {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition:'center',
        height: '450px',
        
      }
    return (
<>
   <Box paddingTop={15} >
    <Container maxWidth='md' >
        {newsDetail? 
        <>
        <Typography  variant="logo">
            {newsDetail.news_topic1}
            </Typography>
            <br/>
        <Typography  variant="h3">
            {newsDetail.news_topic2}
        </Typography>
            <Box marginTop={3}>  <Box style={{ ...divStyle, 'backgroundImage': `url(${LoadImageNewsTopic+newsDetail.news_image})` }}></Box></Box>
            < Typography  textAlign={'center'} variant="h1" fontSize={15}>   <div dangerouslySetInnerHTML={{ __html: newsDetail.news_detail }} /></Typography>
           
            </>
    :''}
    </Container>
    </Box>
    <br/>
     
   </>
  );
};

export default NewsDetail;
