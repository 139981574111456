import { Box, Button, MenuItem, Typography,Select , useTheme,Stack, Paper, Grid, Container,TextField, Divider } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { tokens } from "../../theme";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useContext,useState,useEffect } from "react";
import { mockDataCarShow,mockDataBrand,mockDataNewsSlide} from "../../data/mockData";
import { Slide } from 'react-slideshow-image';
import { Lightbox }  from "react-modal-image";
import FacebookIcon   from '@mui/icons-material/Facebook';
import { FaLine } from "react-icons/fa6";
import { FormControl } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { IoIosSend } from "react-icons/io";
import { FaCarTunnel } from "react-icons/fa6";
import { PiArrowsInCardinalFill } from "react-icons/pi";
import { FaCirclePlay } from "react-icons/fa6";
import { SlSpeedometer } from "react-icons/sl";
import { PiEngineBold } from "react-icons/pi";
import { PiGasPumpBold } from "react-icons/pi";
import { GiGears } from "react-icons/gi";
import { NumericFormatCustom,NumericFloat } from "../../components/Numfield"; 
import { MdOutlineCalculate } from "react-icons/md";
import { NumericFormat } from 'react-number-format';
import {
  FacebookShareButton,
  FacebookIcon as FB, 
  LineShareButton,
  LineIcon
} from "react-share";
import { SelectCarById,LoadImageCar,LoadVideoCar, SaveMailBox } from "../../context";
import Swal from "sweetalert2";
const CarDetail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { carref } = useParams()
  const [open,setOpen] = useState(false);
  const [open2,setOpen2] = useState(false);
  const [videoClick,setVideo] = useState(false);
  const [videoClickMobile,setVideoMobile] = useState(false);
  const [exteriorClick,setExterior] = useState(true);
  const [interiorClick,setInterior] = useState(false);
  const [mon, setMon] = useState(12);
  const [arrMonth,setArrMonth] = useState([]);
  const [interest,setInterest] = useState(0.00);
  const [installments,setInstallments] = useState(null);
  const [carDetail,setCarDetail] = useState([]);
  const [focus,setFocus] = useState();
  const [priceCal,setPriceCal] = useState();
  const [carId,setCarId] = useState()
  const [mailBox, setMailbox] = useState({
    mailbox_name: '',
    mailbox_phone: '',
    mailbox_line_id: '',
    mailbox_message: ''
  });
  const handleChange = (event) => {
     setMon(event.target.value);
  };
const hadleSendMail = async ()=>{

    var formData = new FormData();   
    formData.append("car_id",carId)
    //formData.append("name",name);
    
    Object.keys(mailBox).map((name)=>
      {         
        
        formData.append(name,mailBox[name])      
      }      
    )     
    
    await axios.post(SaveMailBox,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then( res=>{     
         console.log(res)
      Swal.fire({
        title: 'Send Message Success',           
        icon: 'success',
        timer: 3000,
        showCancelButton: false,       
        confirmButtonText: 'Ok',
        
     })
    }).catch(error=>{
      console.log(error);
    });      
}
  const handleTextFieldChange = (event) => {
    setMailbox({
      ...mailBox,
      [event.target.name]: event.target.value,
    });
  };

  const handleCalculate = (e) => {
    
        let year = mon / 12
        let interestYear = parseInt(e) * (interest/100)
        let sumInterest = interestYear * year
        let sumLoan = sumInterest +  parseInt(e) 
        setInstallments(sumLoan / mon)
     
  };
  const loadCarDetail = (car_id) =>{
    setCarId(car_id)
    var formData = new FormData();       
    formData.append("id", car_id);      
    axios.post(SelectCarById,
    formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res=>{     
     // console.log(res)
      setCarDetail(res.data)
    }).catch(error=>{
      console.log(error)
    })
    }
  
  function currencyFormat(num) {
    return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
  const {
    cookies,
    removeCookie
  } = useContext(AppContext);

  const closeLightbox = () => {
    setOpen(false);
    setOpen2(false);
  };
useEffect(()=>{ 

//   if(JSON.stringify(cookies.carDetail) == undefined){
//     window.location.replace("/");
//    }

//   else  {
   
//     loadCarDetail( JSON.stringify(cookies.carDetail) )
//     removeCookie("carDetail",true)
 
//  }
 loadCarDetail(atob(carref).split('$')[1])
},[])
  
   const divStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    backgroundSize: 'contain',
    backgroundRepeat:'no-repeat',
    objectFit:'center',
    height: '400px', 
    width:'100%',
    borderRadius:'20px',
    backgroundPositionX:'center'
  }

  const divStyle2 = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    backgroundSize: 'contain',
    backgroundRepeat:'no-repeat',
    objectFit:'center',
    height: '200px', 
    width:'100%',
    borderRadius:'20px',
    backgroundPositionX:'center'
  }
  window.addEventListener('beforeunload', function (e) {
    //sessionStorage.clear();
  });
  const handleClear =()=>{
      setVideo(false)
      setVideoMobile(false)
      setExterior(false)
      setInterior(false)
      
  }
  useEffect(()=>{
    let arr = []
    for(var i=12;i<84;i++){ arr.push(i) }
    setArrMonth([12,24,36,48,60] )
  },[])
    return (
      <>
      
   <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } ,mt:5}} >
    <Container maxWidth='lg' >
      
      
      <Paper  style={{backgroundColor:colors.primary[400],padding:20}} >
     { 
       carDetail.map((item,index)=>(   
          item.id ==  atob(carref).split('$')[1] &&<>
          <Typography gutterBottom variant="h2" component="div" sx={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase'}}>{item.brand_name +' '+ item.car_model}</Typography>
         
          <Grid container>
              <Grid item xs={8}>
              {open && (
                    <Lightbox
                      hideDownload
                      large={LoadImageCar+focus}
                      onClose={closeLightbox}
                    />  )}
          {videoClick && (item.video_path? <Box display={'flex'} justifyContent={'center'}>  <video  style={{ ...divStyle}} autoPlay={true} controls >
                <source src={LoadVideoCar+item.video_path}  width="100%" type="video/mp4"/>
              </video></Box>
          :'')}
          {exteriorClick &&  <Box className="slide-container" >
            <Slide>
            {item.image_pathEx.split(',').map((slideImage, index)=> (
            
                  <Box onClick={()=> {setOpen(true);setFocus(slideImage)}} style={{ ...divStyle, 'backgroundImage': `url(${LoadImageCar+'Thumbnails_'+slideImage})` }}>               
                  </Box> 
               
           
              ))} 
            </Slide>
          
          </Box>}
          {interiorClick &&  <Box className="slide-container" >
            <Slide >
            {item.image_pathIn.split(',').map((slideImage, index)=> (
            
            <Box onClick={()=> {setOpen(true);setFocus(slideImage)}} style={{ ...divStyle, 'backgroundImage': `url(${LoadImageCar+'Thumbnails_'+slideImage})` }}>               
            </Box> 
         
     
        ))} 
            </Slide>
          
          </Box>}
          
          <Box sx={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
          <Button variant=""  onClick={()=> {handleClear();setExterior(true)}}>
                    <FaCarTunnel fontSize={20} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>Exterior ({item.image_pathEx.split(',').length})</Typography> 
         </Button>
         <Button variant=""  onClick={()=> {handleClear();setInterior(true)}}>
                    <PiArrowsInCardinalFill fontSize={20} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>Interior ({item.image_pathIn.split(',').length})</Typography> 
         </Button>
         <Button variant=""  onClick={()=> {handleClear();setVideo(true)}}>
                    <FaCirclePlay fontSize={20} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>video clip</Typography> 
         </Button>               
                
                
         </Box>
         <Divider> < Typography variant="logo"  fontSize={16}>Car info</Typography>   </Divider>
        <Box display={"flex"} justifyContent={'space-around'} marginTop={2}>
        <Grid container xs={5}>
          <Grid item xs={2}>
           <SlSpeedometer  fontSize={25} />
          </Grid>
          <Grid item xs={10}>
           
          < Typography variant="h4" fontWeight={'bold'}> {item.car_mile} KM</Typography> 
          < Typography variant="logo"  fontSize={16}>Mileage</Typography>  
          </Grid>
         </Grid>
       

         <Grid container  xs={5}>
          <Grid item xs={2}>
           <PiEngineBold  fontSize={25} />
          </Grid>
          <Grid item xs={8}>
           
          < Typography variant="h4" fontWeight={'bold'}> {item.car_cc} CC</Typography> 
          < Typography variant="logo" fontSize={16}>Engine</Typography>  
          </Grid>
         </Grid>
       
       
         <Grid container  xs={5}>
          <Grid item xs={2}>
           <PiGasPumpBold  fontSize={25} />
          </Grid>
          <Grid item xs={8}>
           
          < Typography variant="h4" textTransform={'uppercase'} fontWeight={'bold'}>{item.car_fuel}</Typography> 
          < Typography  variant="logo"  fontSize={16}>Fuel Type</Typography>  
          </Grid>
         </Grid>
       
         <Grid container xs={4}>
          <Grid item xs={2.5}>
           <GiGears  fontSize={25} />
          </Grid>
          <Grid item xs={8}>
           
          < Typography variant="h4" fontWeight={'bold'}>{item.car_year}</Typography> 
          < Typography  variant="logo"  fontSize={16}>Year</Typography>  
          </Grid>
         </Grid>
       
         </Box>
         <br/>
         <Divider> < Typography variant="logo"  fontSize={16}>Overviews</Typography></Divider>
         < Typography variant="logo" fontSize={15}>   <div dangerouslySetInnerHTML={{ __html: item.car_detail }} /></Typography>
          </Grid>
              <Grid item xs={4}>

                <Box style={{borderColor:colors.primary[300],borderRadius:'20px',borderStyle:'dashed',borderWidth:'1px',margin:'0 0 0 20px '}}>
                  <Stack direction='column' gap={2} m={2}>
                    <Button variant="contained" sx={{background:'#07b53b' }} onClick={()=> window.open('https://page.line.me/bigapplecar')} > 
                    <FaLine fontSize={18} style={{marginRight:'10px'}}/>< Typography variant="logo" fontSize={20}>CHAT ON LINE </Typography> 
                    </Button>
                    <Button variant="contained" sx={{background:'#0866ff' }} onClick={()=> window.open('https://www.messenger.com/t/bigapplecar1999')}>
                    <FacebookIcon style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={20}>CHAT ON FACEBOOK </Typography> 
                    </Button>
                     <Box>
                         <Typography sx={{float:'left',mr:2}}>Share: </Typography>
                         <Stack direction={'row'} spacing={1}>
                            <FacebookShareButton url={`www.bigapplecars.com/carDetail/${carref}`} carref><FB size={32} round={true} /></FacebookShareButton>
                            <LineShareButton title={item.brand_name +' '+ item.car_model} url={`www.bigapplecars.com/carDetail/${carref}`}><LineIcon size={32} round={true} /></LineShareButton>
                         </Stack>
                        
                     </Box>
                    </Stack>
                   
                </Box>     
                <br/>
                <Box  style={{borderColor:colors.primary[300],borderRadius:'20px',borderStyle:'dashed',borderWidth:'1px',margin:'0 0 0 20px '}}>
                  <Stack direction='row' gap={2} m={2}>
                      < Typography variant="logo" fontSize={20}>Price</Typography> 
                      < Typography variant="logo" fontSize={30}color={colors.greenAccent[500]} >฿ {currencyFormat(parseInt(item.car_price))}</Typography> 
                  </Stack>
                </Box>     
                <br/>
                <Box style={{background:colors.grey[800],borderRadius:'20px',margin:'0 0 0 20px'}}>
                  <Stack direction='column' gap={2} padding={2}>
                    
                      < Typography variant="logo" fontSize={20}>Car Loan Calculator</Typography> 
                      <Box
                          component="form"
                          sx={{
                            '& > :not(style)': {  width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                        >                       
                         <TextField
                                size="small"  label="Car Price"                                 
                                value={priceCal}
                                defaultValue={parseInt(item.car_price)}
                                onChange={(e)=>setPriceCal(e.target.value)}                                                     
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  style: {fontSize: 25}
                                }}
                                variant="filled"
                              />
                            
                          <TextField
                          size="small"  label="Interest Rate" 
                          value={interest}
                          onChange={(e)=>setInterest(e.target.value)}                                                
                          InputProps={{
                            inputComponent: NumericFloat,
                            style: {fontSize: 25}
                          }}
                          variant="filled"
                        />
                        <FormControl  sx={{minWidth: 120,marginTop:'15px' }} size="small">
                              <InputLabel >Loan period</InputLabel>
                               <Select                         

                                value={mon}
                                label="Loan period"
                                onChange={handleChange}>
                                {arrMonth.map((item)=>(
                                                                  
                                             
                                              <MenuItem value={item}>
                                                < Typography variant="logo" fontSize={15}> {item} month</Typography>
                                              </MenuItem>
                                        
                                ))}                              
                                
                              </Select>
                            </FormControl>
                        </Box>
                      {installments &&
                      <NumericFormat
                          value={installments.toFixed(2)}
                          thousandsGroupStyle="none"
                          thousandSeparator=","
                          displayType="text"
                          renderText={(value) =>
                           <Typography textAlign={'center'} paddingLeft={1} fontSize={20} variant="logo" color={colors.greenAccent[500]}>
                            Payment ฿ {value} / Month </Typography> }
                              />
                      }
                    
                   <Button variant="contained" sx={{background:colors.greenAccent[500] }} onClick={()=>handleCalculate(item.car_price == null ? priceCal:priceCal ? priceCal:item.car_price)}>
                    <MdOutlineCalculate  fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo" fontWeight={'bold'} fontSize={20}>Calculate your loan</Typography> 
                    </Button>
                    </Stack>
                </Box>               
                <br/>         
                 <Box style={{background:colors.primary[400],borderRadius:'20px',margin:'0 0 0 20px'}}>
                  <Stack direction='column' gap={2} padding={2}>
                    
                      < Typography variant="logo" fontSize={20}>ลงทะเบียน รับสิทธิพิเศษ</Typography> 
                      <FormControl>
                        <InputLabel htmlFor="my-name">Name*</InputLabel>
                        <Input id="my-name"        onChange={handleTextFieldChange} name ="mailbox_name" inputProps={{ maxLength: 100 }} />
                        </FormControl>
                        <FormControl>
                        <InputLabel htmlFor="my-phone">Phone*</InputLabel>
                        <Input id="my-phone"        onChange={handleTextFieldChange}  inputProps={{  min: 0 }}   name ="mailbox_phone" type={'number'} />
                        </FormControl>                 
                        <FormControl>
                        <InputLabel htmlFor="my-input"  >ID Line</InputLabel>
                        <Input id="my-input"        onChange={handleTextFieldChange} inputProps={{ maxLength: 20 }}   name ="mailbox_line_id" type="text" aria-describedby="my-helper-text" />
                        <FormHelperText id="my-helper-text">We'll never share your Line ID.</FormHelperText>
                      </FormControl>
                      
                      <TextField
                      id="standard-multiline-static"
                      label="Message*"
                      onChange={handleTextFieldChange}
                      name ="mailbox_message"
                      multiline
                      rows={3}                   
                      variant="filled"
                    />
                   <Button onClick = {()=>hadleSendMail()} variant="contained" sx={{background:colors.primary[500] }}>
                    <IoIosSend  fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={20}>SEND CONTACT</Typography> 
                    </Button>
                    </Stack>
                </Box>                           
              </Grid>
             
            </Grid>
          </>
       ))
     }
 
      </Paper>
     

      <br/>





    

    </Container>
 
    </Box>
    <Box sx={{ display: { xs: 'block', sm: 'none' } ,mt:5}} >
    <Container maxWidth='lg' >
      
      
      <Paper  style={{backgroundColor:colors.primary[400],padding:20}} >
     { 
       carDetail.map((item,index)=>(   
          item.id ==  atob(carref).split('$')[1] &&<>
          <Typography gutterBottom variant="h4" component="div" sx={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase'}}>{item.brand_name +' '+ item.car_model}</Typography>
    
            
               {open2 && (
                    <Lightbox
                      hideDownload
                      large={LoadImageCar+focus}
                      onClose={closeLightbox}
                    />  )} 
          {videoClickMobile && (item.video_path? <Box display={'flex'} justifyContent={'center'}>  <video  style={{ ...divStyle2}} autoPlay={true} controls >
                <source src={LoadVideoCar+item.video_path}  width="100%" type="video/mp4"/>
              </video></Box>
          :'')}
          {exteriorClick &&  <Box className="slide-container" >
            <Slide>
            {item.image_pathEx.split(',').map((slideImage, index)=> (
            
                  <Box onClick={()=> {setOpen2(true);setFocus(slideImage)}} style={{ ...divStyle2, 'backgroundImage': `url(${LoadImageCar+'Thumbnails_'+slideImage})` }}>               
                  </Box> 
               
           
              ))} 
            </Slide>
          
          </Box>}
          {interiorClick &&  <Box className="slide-container" >
            <Slide >
            {item.image_pathIn.split(',').map((slideImage, index)=> (
            
            <Box onClick={()=> {setOpen2(true);setFocus(slideImage)}} style={{ ...divStyle2, 'backgroundImage': `url(${LoadImageCar+'Thumbnails_'+slideImage})` }}>               
            </Box> 
         
     
        ))} 
            </Slide>
          
          </Box>}
          
          <Box sx={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
          <Button variant=""  onClick={()=> {handleClear();setExterior(true)}}>
                    <FaCarTunnel fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>Exterior</Typography> 
         </Button>
         <Button variant=""  onClick={()=> {handleClear();setInterior(true)}}>
                    <PiArrowsInCardinalFill fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>Interior</Typography> 
         </Button>
         <Button variant=""  onClick={()=> {handleClear();setVideoMobile(true)}}>
                    <FaCirclePlay fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>video</Typography> 
         </Button>               
                
                
         </Box>
           <Grid container >
             <Grid item xs={6} >
               <Box style={{ margin: '0 0 0 20px ' }}>
                 <Stack direction='column' gap={2} m={2}>
                   <Button variant="contained" sx={{ background: '#07b53b' }} onClick={() => window.open('https://page.line.me/bigapplecar')} >
                     <FaLine fontSize={18} style={{ marginRight: '10px' }} />< Typography variant="logo" fontSize={15}>LINE </Typography>
                   </Button>
                   <Button variant="contained" sx={{ background: '#0866ff' }} onClick={() => window.open('https://www.messenger.com/t/bigapplecar1999')}>
                     <FacebookIcon style={{ marginRight: '10px' }} /> < Typography variant="logo" fontSize={15}>FACEBOOK </Typography>
                   </Button>
                 </Stack>
                 <Box>
                         <Typography sx={{float:'left',mr:2}}>Share: </Typography>
                         <Stack direction={'row'} spacing={1}>
                            <FacebookShareButton url={`www.bigapplecars.com/carDetail/${carref}`} carref><FB size={32} round={true} /></FacebookShareButton>
                            <LineShareButton title={item.brand_name +' '+ item.car_model} url={`www.bigapplecars.com/carDetail/${carref}`}><LineIcon size={32} round={true} /></LineShareButton>
                         </Stack>
                        
                     </Box>
               </Box>
             </Grid>
             <Grid item xs={6} alignSelf={'center'} >
               <Box style={{ borderColor: colors.primary[300], borderRadius: '20px',padding:15, borderStyle: 'dashed', borderWidth: '1px', margin: '0 0 0 20px ' }}>
                
                   < Typography variant="logo" fontSize={15}>Price</Typography><br/>
                   < Typography variant="logo" fontSize={22} color={colors.greenAccent[500]} >฿ {currencyFormat(parseInt(item.car_price))}</Typography>
                
               </Box>
             </Grid>
           </Grid>
         <Divider> < Typography variant="logo"  fontSize={16}>Car info</Typography>   </Divider>
        <Box display={"flex"} justifyContent={'space-around'} marginTop={2}>
        <Grid container xs={5}>
          <Grid item xs={2}>
           <SlSpeedometer  fontSize={17}  />
          </Grid>
          <Grid item xs={10} pl={1}>
           
          < Typography variant="h5" fontWeight={'bold'}>{item.car_mile} KM</Typography> 
          < Typography variant="logo"  fontSize={12}>Mileage</Typography>  
          </Grid>
         </Grid>
       

         <Grid container  xs={5}>
          <Grid item xs={2}>
           <PiEngineBold  fontSize={17} />
          </Grid>
          <Grid item xs={10}  pl={1}>
           
          < Typography variant="h5" fontWeight={'bold'}> {item.car_cc} CC</Typography> 
          < Typography variant="logo" fontSize={12}>Engine</Typography>  
          </Grid>
         </Grid>
       
       
         <Grid container  xs={5}>
          <Grid item xs={2}>
           <PiGasPumpBold  fontSize={17} />
          </Grid>
          <Grid item xs={7}  pl={1}>
           
          < Typography variant="h6" textTransform={'uppercase'} fontWeight={'bold'}>{item.car_fuel}</Typography> 
          < Typography  variant="logo"  fontSize={12}>Fuel Type</Typography>  
          </Grid>
         </Grid>
       
         <Grid container xs={3}>
          <Grid item xs={2.5}>
           <GiGears  fontSize={17} />
          </Grid>
          <Grid item xs={8}  pl={1}>
           
          < Typography variant="h6" fontWeight={'bold'}>{item.car_year}</Typography> 
          < Typography  variant="logo"  fontSize={12}>Year</Typography>  
          </Grid>
         </Grid>
       
         </Box>
         <br/>
         <Divider> < Typography variant="logo"  fontSize={16}>Overviews</Typography></Divider>
         < Typography variant="logo" fontSize={15}>   <div dangerouslySetInnerHTML={{ __html: item.car_detail }} /></Typography>
          
             
           
                   
              
                <Box style={{background:colors.grey[800],borderRadius:'20px'}}>
                  <Stack direction='column' gap={2} padding={2}>
                    
                      < Typography variant="logo" fontSize={20}>Car Loan Calculator</Typography> 
                      <Box
                          component="form"
                          sx={{
                            '& > :not(style)': {  width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                        >                       
                         <TextField
                                size="small"  label="Car Price"                                 
                                value={priceCal}
                                defaultValue={parseInt(item.car_price)}
                                onChange={(e)=>setPriceCal(e.target.value)}                                                     
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  style: {fontSize: 25}
                                }}
                                variant="filled"
                              />
                            
                          <TextField
                          size="small"  label="Interest Rate" 
                          value={interest}
                          onChange={(e)=>setInterest(e.target.value)}                                                
                          InputProps={{
                            inputComponent: NumericFloat,
                            style: {fontSize: 25}
                          }}
                          variant="filled"
                        />
                        <FormControl  sx={{minWidth: 120,marginTop:'15px' }} size="small">
                              <InputLabel >Loan period</InputLabel>
                               <Select                         

                                value={mon}
                                label="Loan period"
                                onChange={handleChange}>
                                {arrMonth.map((item)=>(
                                                                  
                                             
                                              <MenuItem value={item}>
                                                < Typography variant="logo" fontSize={15}> {item} month</Typography>
                                              </MenuItem>
                                        
                                ))}                              
                                
                              </Select>
                            </FormControl>
                        </Box>
                      {installments &&
                      <NumericFormat
                          value={installments.toFixed(2)}
                          thousandsGroupStyle="none"
                          thousandSeparator=","
                          displayType="text"
                          renderText={(value) =>
                           <Typography textAlign={'center'} paddingLeft={1} fontSize={20} variant="logo" color={colors.greenAccent[500]}>
                            Payment ฿ {value} / Month </Typography> }
                              />
                      }
                    
                   <Button variant="contained" sx={{background:colors.greenAccent[500] }} onClick={()=>handleCalculate(item.car_price == null ? priceCal:priceCal ? priceCal:item.car_price)}>
                    <MdOutlineCalculate  fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo" fontWeight={'bold'} fontSize={20}>Calculate your loan</Typography> 
                    </Button>
                    </Stack>
                </Box>               
                <br/>         
                 <Box style={{background:colors.primary[400],borderRadius:'20px'}}>
                  <Stack direction='column' gap={2} padding={2}>
                    
                      < Typography variant="logo" fontSize={20}>ลงทะเบียน รับสิทธิพิเศษ</Typography> 
                      <FormControl>
                        <InputLabel htmlFor="my-name">Name*</InputLabel>
                        <Input id="my-name"        onChange={handleTextFieldChange} name ="mailbox_name" inputProps={{ maxLength: 100 }} />
                        </FormControl>
                        <FormControl>
                        <InputLabel htmlFor="my-phone">Phone*</InputLabel>
                        <Input id="my-phone"        onChange={handleTextFieldChange}  inputProps={{  min: 0 }}   name ="mailbox_phone" type={'number'} />
                        </FormControl>                 
                        <FormControl>
                        <InputLabel htmlFor="my-input"  >ID Line</InputLabel>
                        <Input id="my-input"        onChange={handleTextFieldChange} inputProps={{ maxLength: 20 }}   name ="mailbox_line_id" type="text" aria-describedby="my-helper-text" />
                        <FormHelperText id="my-helper-text">We'll never share your Line ID.</FormHelperText>
                      </FormControl>
                      
                      <TextField
                      id="standard-multiline-static"
                      label="Message*"
                      onChange={handleTextFieldChange}
                      name ="mailbox_message"
                      multiline
                      rows={3}                   
                      variant="filled"
                    />
                   <Button onClick = {()=>hadleSendMail()} variant="contained" sx={{background:colors.primary[500] }}>
                    <IoIosSend  fontSize={25} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={20}>SEND CONTACT</Typography> 
                    </Button>
                    </Stack>
                </Box>                           
            
          </>
       ))
     }
 
      </Paper>
     

      <br/>





    

    </Container>
 
    </Box>
    </>
  );
};

export default CarDetail;
