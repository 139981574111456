import { Box, Paper, Container, Typography, useTheme,Stack } from "@mui/material";
import { tokens } from "../../theme";
import { SelectNewsTopicById,LoadImageNewsTopic, SelectNewsSubById, LoadImageNewsSub } from "../../context";
import axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";
import { mockDataBrand } from "../../data/mockData";


const NewsListDetail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newsDetail,setNewsDetail] = useState()
    useEffect(()=>{
                
            var formData = new FormData();       
            formData.append("id",   localStorage.newsSubId);      
            axios.post(SelectNewsSubById,
            formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(res=>{     
                setNewsDetail(res.data[0])
               
        
            }).catch(error=>{
              console.log(error)
            })
           
    },[])

    const divStyle = {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition:'center',
        height: '450px',
        
      }
    return (
<>
   <Box paddingTop={15} >
    <Container maxWidth='md' >
        {newsDetail? 
        <>
        <Typography  variant="logo">
            {newsDetail.newssub_topic}
            </Typography>
            <br/>
       
            <Box marginTop={3}>  <Box style={{ ...divStyle, 'backgroundImage': `url(${LoadImageNewsSub+newsDetail.newssub_image})` }}></Box></Box>
            < Typography  variant="h1" fontSize={15}>   <div dangerouslySetInnerHTML={{ __html: newsDetail.newssub_detail }} /></Typography>
           
            </>
    :''}
    </Container>
    </Box>
    <br/>
     
   </>
  );
};

export default NewsListDetail;
