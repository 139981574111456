import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import CarDetail from "./scenes/cardetail";
import Home from "./scenes/home";
import Show from "./scenes/show";
import { CssBaseline, ThemeProvider,Button,Typography, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Carlist from "./scenes/carlist"
import CardTest from "./scenes/test";
import NewsDetail from "./scenes/newsDetail";
import NewsListDetail from "./scenes/newsListDetail";
import Bottombar from "./scenes/global/Bottombar";
import { FaLine } from "react-icons/fa6";
import Badge from '@mui/material/Badge';
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [iframeClick,setClick]= useState(false);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/carlist" element={<Carlist/>} />
              <Route path="/carDetail/:carref" element={<CarDetail/>} />
              <Route path="/newsDetail" element={<NewsDetail/>} />
              <Route path="/newsListDetail" element={<NewsListDetail/>} />
              {/* <Route path="/ex" element={<CardTest/>} /> */}
            </Routes>
            <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
   
               <Button variant="contained" sx={{background:'#07b53b',position:'fixed',bottom:0,right:0,zIndex:1,borderRadius:40 ,marginRight:2,marginBottom :8}} onClick={()=> window.open('https://page.line.me/bigapplecar')} > 
              <Badge sx={{left:87,top:-10}} color="error" badgeContent=""  variant="dot"  />  <FaLine fontSize={12} style={{marginRight:'10px'}}/>< Typography variant="h4" fontWeight={'bold'} fontSize={12}>LINE CHAT</Typography> 
              </Button> 
          
         </Box>
         <Box sx={{ display: { xs: 'none', sm: 'flex' }}}>

         <Button variant="contained" sx={{background:'#07b53b',position:'fixed',bottom:0,right:0,zIndex:1,borderRadius:40 ,marginRight:5,marginBottom :5}} onClick={()=> window.open('https://page.line.me/bigapplecar')} > 
              <Badge sx={{left:130,top:-15}} color="error" badgeContent=""  variant="dot"  />  <FaLine fontSize={20} style={{marginRight:'10px'}}/>< Typography variant="h4" fontWeight={'bold'} fontSize={20}>LINE CHAT</Typography> 
              </Button> 
         </Box>
            <Bottombar setIsSidebar={setIsSidebar} />
            
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
