import React,{useState,useEffect} from 'react';
import Card from '@mui/material/Card';
import {  Box, Stack, useTheme } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SpeedIcon from '@mui/icons-material/Speed';
import { colors } from '@mui/material';
import { LoadImageCar } from "../../context";
import { tokens } from "../../theme";
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import "../../index.css"
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { NumericFormat } from 'react-number-format';
export default  function CardCardSell({mockData,handleCardetail}) {
  const theme = useTheme();
  const [index,setIndex] = useState(0)
  const colors = tokens(theme.palette.mode);
  let widthInit = 100
  let i =0
  function currencyFormat(num) {
    return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

  return (
  
    <Grid container spacing={{ xs:1 , md: 2 }} sx={{placeContent:'center'}} columns={{ xs: 4, sm: 8, md: 12 }}
     >   
        
            {mockData.map((value,index) => (
                <Grid item  key={index+1}>
             <Card  sx={{ maxWidth: 315 ,minWidth: 280,borderRadius:'30px', }}>
             
             {value.car_promotion == 0?  <Box className={'ribbon-2'} style={{zIndex:1000,position:'relative', marginBottom:'-210px',marginTop:'177px'}} variant='logo' fontSize={15}>HOT SALE</Box>:''}
                   <CardMedia
                     sx={{ height: 180,margin:1,borderRadius:'30px' }}
                     image={LoadImageCar+'Thumbnails_'+value.image_pathEx }
                     title="green iguana"
                   />
                     
                   <CardContent>
                   <Tooltip followCursor title= { <Typography gutterBottom variant="logo" fontSize={18} component="div">
                         {value.brand_name + ' '+  value.car_model} 
                        </Typography>} >
                        <Typography gutterBottom variant="logo" fontSize={18} component="div" sx={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                         {value.brand_name + ' '+  value.car_model} 
                        </Typography>
                        </Tooltip>
                     <Grid container xs={12}>
                       <Grid item marginBottom={1} xs={6}><SpeedIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={15}>{currencyFormat(parseInt(value.car_mile))+ ' Km'}</Typography></Grid>
                       <Grid item marginBottom={1} xs={6}><ColorLensIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={15}>{value.color_name}</Typography></Grid>
                       <Grid item marginBottom={1} xs={6}><ScheduleSendIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={15}>{value.car_year}</Typography></Grid>
                       <Grid item marginBottom={1} xs={6}><GasMeterIcon sx={{marginBottom:'-5px'}}/><Typography variant='logo' paddingLeft={1} fontSize={15}>{value.car_fuel}</Typography></Grid>
                     </Grid>
             </CardContent>
                   <Divider sx={{width:'80%',marginLeft:3,marginRight:3 }}></Divider>
                   <CardActions>
                     <Grid container>
                       <Grid item xs={8}>
                       <NumericFormat
                             value={parseInt (value.car_price)}
                             thousandsGroupStyle="none"
                             thousandSeparator=","
                             displayType="text"
                             suffix=".00"
                             renderText={(value) =><Typography paddingLeft={1} fontSize={20} variant="logo" color={colors.greenAccent[500]}>
                             {'฿ ' +value}
                             
                           </Typography>}
                           />
                     
                     </Grid>
                     <Grid item xs={4}>
                     <Button size="small" onClick={()=> handleCardetail(value.id)} sx={{background:colors.blueAccent[700],color:'#FFF'}}>Learn More</Button>
                     </Grid>
                     </Grid>
                   </CardActions>
                 </Card>
             </Grid>
            ))}
       </Grid>

              
  );
}