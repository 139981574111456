import React, { createContext, useState,useRef,useEffect} from "react";
import { useCookies } from 'react-cookie';

const AppContext = createContext();


const ContextProvider = ({ children }) => {
  
  const newArrivalRef = useRef(null);
  const homeRef = useRef(null);
  const socialContact = useRef(null);
  const news = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies(['brandClick']);
  const [brand,setBrand] = useState('')
  // useEffect(()=>{
  //   if(cookies.brand != ''){
  //      setBrand(cookies.brand) 
  //      setCookie('brand','')
  //   }
  // },[cookies.brand])
  return (
    <AppContext.Provider
      value={{
        setCookie,
        cookies,
        newArrivalRef,
        homeRef,
        socialContact,
        news,
        brand,
        removeCookie
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { ContextProvider, AppContext };
