import { Box, Grid, useTheme,Paper ,Container,Link} from "@mui/material";
import { useContext,useRef } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { mockDataBrand } from "../../data/mockData";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { AppContext } from "../../AppContext";
import {useNavigate,useLocation} from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaLine } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
function Bottombar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const location = useLocation()
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (ref) => {
    {location.pathname != '/' && navigate('/')} 
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      
    </Box>
  );



  return (
    <Paper style={{position:'absolute',width:'100%',marginTop:30}}>
    <Container maxWidth='lg' >
    <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
          <br/>
              <Typography style={{marginRight:'10px',marginLeft:'10px'}} >
                
                ที่อยู่ 208 ถนนราชพฤกษ์ แขวงบางจาก เขตภาษีเจริญ <br/>
                Bangkok, Thailand, Bangkok  (66+)88 820 4444
                <br/>
                Mon-Sat 09:00 - 18:00
                <br/>
                Copyright © BIGAPPLECAR 2024
                </Typography>
          <br/>
           
      <br/>
      <Grid container>
       <Grid item xs={5}>
      
          <FacebookIcon style={{marginRight:'10px',fontSize:60}}/> 
         
          < Typography variant="logo" position={'absolute'}  marginTop={'2px'} fontSize={15}> 
            Contact Facebook
           </Typography> 
          <Typography  style={{cursor:'pointer'}} variant="logo" position={'absolute'} marginTop={'22px'}> <Link style={{textDecoration: "none",boxShadow: "none"}} color={colors.grey[100]} href="https://www.facebook.com/bigapplecar1999">bigapplecar1999</Link></Typography>
       </Grid>
       
       <Grid item xs={4}>
        
       <FaLine style={{marginRight:'10px',fontSize:60}}/> 
         
         < Typography variant="logo" position={'absolute'}  marginTop={'2px'} fontSize={15}> 
           Contact Line
          </Typography> 
         <Typography  style={{cursor:'pointer'}} variant="logo" position={'absolute'} marginTop={'22px'}> <Link style={{textDecoration: "none",boxShadow: "none"}} color={colors.grey[100]} href="https://page.line.me/bigapplecar">@bigapplecar</Link></Typography>
        </Grid>
        <Grid item xs={3}>
       <FaTiktok  style={{marginRight:'10px',fontSize:60}}/> 
         
         < Typography variant="logo" position={'absolute'}  marginTop={'2px'} fontSize={15}> 
           Contact Tiktok
          </Typography> 
         <Typography  style={{cursor:'pointer'}} variant="logo" position={'absolute'} marginTop={'22px'}> <Link style={{textDecoration: "none",boxShadow: "none"}} color={colors.grey[100]} href="https://www.tiktok.com/@bigapple_car">BigappleCar</Link></Typography>
        </Grid>

      
      </Grid>
      
      <br/>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
          <br/>
              <Typography style={{marginRight:'10px',marginLeft:'10px'}} >
                
                ที่อยู่ 208 ถนนราชพฤกษ์ แขวงบางจาก เขตภาษีเจริญ <br/>
                Bangkok, Thailand, Bangkok  (66+)88 820 4444
                <br/>
                Mon-Sat 09:00 - 18:00
                <br/>
                Copyright © BIGAPPLECAR 2024
                </Typography>
          <br/>
           
      <br/>
      <Grid container>
       <Grid item xs={4.8}>
      
          <FacebookIcon style={{marginRight:'5px',fontSize:30}}/> 
           <Typography  style={{cursor:'pointer'}}  position={'absolute'}  fontSize={11} variant="logo"  marginTop={'6px'}> <Link style={{textDecoration: "none",boxShadow: "none"}} color={colors.grey[100]} href="https://www.facebook.com/bigapplecar1999">bigapplecar1999</Link></Typography>
       </Grid>
       
       <Grid item xs={4}>
        
       <FaLine style={{marginRight:'5px',fontSize:25}}/> 
         <Typography  style={{cursor:'pointer'}} fontSize={11} variant="logo" position={'absolute'} marginTop={'6px'}> <Link style={{textDecoration: "none",boxShadow: "none"}} color={colors.grey[100]} href="https://page.line.me/bigapplecar">@bigapplecar</Link></Typography>
        </Grid>
        <Grid item xs={3}>
       <FaTiktok  style={{marginRight:'5px',fontSize:25}}/> 
        
         <Typography  style={{cursor:'pointer'}} fontSize={11} variant="logo" position={'absolute'} marginTop={'6px'}> <Link style={{textDecoration: "none",boxShadow: "none"}} color={colors.grey[100]} href="https://www.tiktok.com/@bigapple_car">BigappleCar</Link></Typography>
        </Grid>

      
      </Grid>
      
      <br/>
      </Box>
    
    </Container>
  </Paper>
  );
}

Bottombar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Bottombar;