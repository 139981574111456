import { Box, Button, IconButton, Typography, useTheme,Stack, Paper, Grid, Container,Card } from "@mui/material";
import { SelectNewArrival,SelectNewsTopic,SelectPromotion,LoadImageNewsTopic } from "../../context";
import { tokens } from "../../theme";
import axios from 'axios';
import { url } from "./context";
import BrandList from "./brandList";
import CardCard from "./carCardList";
import NewSlider from "./newsSlider";
import NewsList from "./newsList";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { mockDataCarShow,mockDataNewCar,mockDataBrand} from "../../data/mockData";
import Slideshow from "./slideShow";
import Iframe from 'react-iframe'
import { useState } from "react";
import { useEffect } from "react";
import InstagramEmbed from 'react-instagram-embed';
import CardCardPromo from "./carCardListMobile";
const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newArrival,setNewarrival] = useState(null)
  const [promotion,setPromotion] = useState(null)
  const [newsList,setNewsList] = useState([])

    useEffect(()=>{
      loadCar()
      loadCarPromotion()
      loadNewsSlide()
    },[])

  const {
    newArrivalRef,
    homeRef,
    socialContact,
    news,
    setCookie
  } = useContext(AppContext);
  const handleBrand = (e)=>{
     localStorage.removeItem("brand")
     setCookie('brand',e)     
     window.open('/carlist', '_blank', 'noreferrer')
  }
  const loadNewsSlide = async () =>{
    
   await axios.get(SelectNewsTopic)
    .then(res=>{      
      const collection = []
      res.data.map((item, index)=> {
        
        collection.push({ src:LoadImageNewsTopic + item.news_image , caption: item.news_topic1,id:item.id })    
      })
      setNewsList(collection)
  
    }).catch(error=>{
      console.log(error);
    })
    }
  const loadCar = () =>{
    
    axios.get(SelectNewArrival)
    .then(res=>{
      
      setNewarrival(res.data);
     
    }).catch(error=>{
      console.log(error);
    })
    }
    const loadCarPromotion = () =>{
    
      axios.get(SelectPromotion)
      .then(res=>{
        
        setPromotion(res.data);
  
      }).catch(error=>{
        console.log(error);
      })
      }
    return (
      <>
    <Box  ref={homeRef}>
    <Container maxWidth='lg' >
       {/* <img 
         alt="cover"
         width="100%"
         height="760px"
         src={`../../assets/cover.jpg`}
         style={{ cursor: "pointer", borderRadius: "30px",maxWidth:'100%',minWidth:'400px',
         objectFit:'cover',objectPosition:'left',position:'absolute',right:'30px',top:'100px',zIndex:-1}}
         /> */}
         
      <Grid container >  
      <Grid item xs={2.8}  sx={{  display: { xs: 'none', sm: 'block' } }}>
       
         
        <Box >
            <img 
            alt="cover"
            width="240px"
            
            src={`../../assets/logo0.png`}
            style={{borderRadius: "200px",minWidth:'100px',marginTop:10}}
            />
           
            {/* <BrandList/> */}
        </Box>
       
      </Grid>
      <Box sx={{  display: { xs: 'flex', sm: 'none' },width:'100%',mt:3 }}
        
        justifyContent="center"
        alignItems="center"
     >
      
        <Box>
        <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',animationDelay:'100ms',textShadow:'3px 0px 1px #b9a588' }}  fontSize={34}>GET</Typography>
              <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',animationDelay:'200ms',marginTop:-1,textShadow:'3px 0px 1px #b9a588' }}  fontSize={20}>READY</Typography>
              <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',animationDelay:'300ms',marginTop: -0.5,textShadow:'3px 0px 1px #b9a588'  }}  fontSize={20}>FOR</Typography>
        </Box>
            
        <Box>
             
              <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',textShadow:'5px -1px 1px #b9a588',ml:1}}  fontSize={90}>DRIVE</Typography>
        </Box>
       
        
      
            {/* <Typography variant="h1"  sx={{animation:' 3s anim-lineLeft',animationDelay:'50ms'}} color={colors.greenAccent[500]} >BIGAPPECARS.COM</Typography> */}
      </Box>

      <Box sx={{  display: { xs: 'flex', sm: 'none' },width:'100%' }}  justifyContent="center"
        alignItems="center">
            <img 
            alt="cover"
            width="240px"
            
            src={`../../assets/cover01.jpg`}
            style={{borderRadius: "10px",width:'100%',marginTop:10}}
            />
        
            {/* <BrandList/> */}
        </Box>

      <Grid item alignSelf='center' xs={2.2}>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} >
            <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',animationDelay:'100ms',marginLeft:-1,textShadow:'3px 0px 1px #b9a588' }}  fontSize={95}>GET</Typography>
            <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',animationDelay:'200ms',marginTop:-2,textShadow:'3px 0px 1px #b9a588' }}  fontSize={55}>READY</Typography>
            <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',animationDelay:'300ms',marginTop:-2,textShadow:'3px 0px 1px #b9a588'  }}  fontSize={55}>FOR</Typography>
           
            {/* <Typography variant="h1"  sx={{animation:' 3s anim-lineLeft',animationDelay:'50ms'}} color={colors.greenAccent[500]} >BIGAPPECARS.COM</Typography> */}
        </Box>
       
      
      </Grid>
      <Grid item xs={6.8}>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} >
            <Typography variant="h1"  sx={{animation:' 3s anim-lineUp ease-out ',textShadow:'5px -1px 1px #b9a588' }}  fontSize={230}>DRIVE</Typography>
            {/* <Typography variant="h1"  sx={{animation:' 3s anim-lineLeft',animationDelay:'50ms'}} color={colors.greenAccent[500]} >BIGAPPECARS.COM</Typography> */}
        </Box>
      
      </Grid>
      <Grid item xs={7} sx={{ display: { xs: 'none', sm: 'block' }}}>  
       <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
            <BrandList handleBrand={(e)=>handleBrand(e)}/> 
       </Box>   
     </Grid>
     <Grid item xs={5} sx={{ display: { xs: 'none', sm: 'block' }}}>   
      <Box sx={{textAlign:'end'}} >
             <Typography variant="h1"  sx={{animation:' 4s anim-lineUp ease-out ' }}  
             fontSize={30}>Professionally Enhance your car with BIG GUARANTEE from </Typography>
                  <Typography variant="h1"  sx={{animation:' 4s anim-lineUp ease-out ',color:'#b9a588' }}  
             fontSize={30}>BIGAPPLE CAR.</Typography>

              <img 
         alt="cover"
         width="100%"
         height="310px"
         src={`../../assets/bannerCar.png`}
         style={{marginLeft:30,minWidth:'400px',zIndex:-1,animation:' 3s zoom-in-zoom-out ease-out '}}
         
         />
      </Box>
      
    </Grid>
    <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
            <BrandList handleBrand={(e)=>handleBrand(e)}/> 
       </Box>  
     </Grid>
     </Container>
    </Box>


    <Box  ref={newArrivalRef} paddingTop={5} >
       <Box  sx={{  display: { xs: 'none', sm: 'flex' }}}>
       <Container maxWidth='lg' >
        <br/><br/><br/>
        
       <Typography marginBottom={10}  marginRight='60%' variant="logo"  fontSize={45}>NEW ARRIVAL</Typography>
       <Typography  variant="logo"  fontSize={20} sx={{cursor:'pointer'}} onClick={()=>{window.open('/carlist', '_blank', 'noreferrer')}}>{'see more >'}</Typography>
       <br/><br/><br/>
        <CardCard mockData={newArrival ?? []} mockPomo = {promotion ?? [{}]}/>
        </Container>
        </Box>
        <Box sx={{  display: { xs: 'block', sm: 'none' }}}>
    
        <Container maxWidth='lg' > <br/>
  
        <Typography marginBottom={10}  variant="logo"  >NEW ARRIVAL</Typography>
        <Typography  variant="logo"  fontSize={10} sx={{cursor:'pointer',display:'flex',justifyContent:'end',mt:-3}} onClick={()=>{window.open('/carlist', '_blank', 'noreferrer')}}>{'see more >'}</Typography>
        <br/>
      
          <CardCardPromo mockData={newArrival ?? []} mockPomo = {promotion ?? [{}]}/>
        
        </Container>
      </Box>
    </Box>

    
   

    <Box  ref={news} paddingTop={5} paddingBottom={5}>
    
    <Container maxWidth='lg' >
    
     <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} >
     <br/><br/><br/>
     <Typography marginBottom={10}  marginRight='50' variant="logo"  fontSize={45}>BIGAPPLE CAR NEWS</Typography>
      
      <br/><br/>
      
      <Grid container spacing={1} xs={12}>
        <Grid  item xs={8}>
          {/* <NewSlider/>              */}

            <Slideshow
              input={newsList}
              ratio={`16:9`}
              mode={`automatic`}
              timeout={`3000`}
            />
        </Grid>
        <Grid item xs={4}>
          <NewsList/>
        </Grid>
      </Grid>
     </Box>
     <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }} >
     <Typography marginBottom={10}  marginRight='50' variant="logo"  fontSize={20}>BIGAPPLE CAR NEWS</Typography>
      
      <br/><br/>
      <Slideshow
              input={newsList}
              ratio={`16:9`}
              mode={`automatic`}
              timeout={`3000`}
       />
    
          <NewsList/>
     
     </Box>

        </Container>
        
    </Box>

    <Box  ref={socialContact} paddingTop={5} paddingBottom={5}>
    
        <Container maxWidth='lg' >

        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} > 
         <Typography marginBottom={10}  marginRight='60%' variant="logo"  fontSize={45}>Social Content</Typography>
          <Box textAlign={'center'}>
        
        
    
          <Iframe url="https://www.tiktok.com/embed/@bigapple_car"
                width="500px"
                height="500px"
                position="relative"
                frameBorder ="0"
              />
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbigapplecar1999&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId" width="500" height="500" 
          style={{border:'none',overflow:'hidden'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }} >
        <Typography marginBottom={20}  variant="logo"  fontSize={20}>Social Content</Typography>
        <br/><br/>
          <Box textAlign={'center'}>

          <Iframe url="https://www.tiktok.com/embed/@bigapple_car"
                width="100%"
                height="500px"
                position="relative"
                frameBorder ="0"
                styles={{marginBottom:15}}
              />
           
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbigapplecar1999&tabs=timeline&width=400&height=600&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId" width="100%" height="500" 
          style={{border:'none',overflow:'hidden'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      
          </Box>
        </Box>
        </Container>
        
    </Box>
  
    </>
  );
};

export default Home;
